<template>
  <div class="detail-container">
    <div class="detail">
      <h3 class="detail-title">
        {{ detail.title }}
      </h3>
      <p class="detail-date">
        {{ detail.pub_date }}
      </p>
      <p
        class="detail-desc"
        v-html="detail.content"
      />
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      detail: {}
    }
  },
  mounted () {
    // 获取新闻详情
    this.getNewsDetail(this.$route.params.id)
  },
  methods: {
    getNewsDetail (id) {
      this.$store.dispatch('getNewsDetail', id).then(res => {
        // console.log("新闻详细", res);
        res.content = res.content.replace(/width="[^"]+"/g, '');
        res.content = res.content.replace(/height="[^"]+"/g, '');
        res.content = res.content.replace(/text-align: center;/g, 'text-align:center;text-indent:0; margin:10px auto');
        //res.content = res.content.
        this.detail = res
      })
    }
  }
}
</script>
<style lang="less" scoped>
.detail-container {
  background: #fafafa;
  .detail {
    background: #fff;
    box-sizing: border-box;
    .detail-title {
      color: #3d3d3d;
      font-weight: normal;
    }
    .detail-date {
      color: #777;
    }
    .detail-desc {
      text-align: left;
      text-indent: 2em;
      letter-spacing: 2px;
      color: #777;
    }
  }
  .detail-next {
    width: 1200px;
    margin: 0 auto;
    background: #fafafa;
    text-align: right;
    a {
      display: inline-block;
      padding: 20px;
      color: #3b3b3b;
      letter-spacing: 1px;
    }
  }
}

::v-deep{
  .detail-desc{
    p{
      margin:0;
    }
    img{
      //max-width: 80% !important;
      width:500px !important;
    }
  }
}
/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {
  .detail-container {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .detail {
    width: 1200px;
    margin: 0 auto 0;
    padding: 40px 120px;
    .detail-title {
      font-size: 32px;
      letter-spacing: 4px;
    }
    .detail-date {
      font-size: 23px;
      margin-top: -10px;
      margin-bottom: 40px;
    }
    .detail-desc {
      margin-bottom: 35px;
      line-height: 30px;
    }
  }
}
/* -----------适应pc端(中屏幕)--------------- */
@media screen and (min-width: 700px) and (max-width: 1160px) {
  .detail-container {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .detail {
    width: 100%;
    padding: 40px 40px;
    .detail-title {
      font-size: 28px;
      letter-spacing: 4px;
    }
    .detail-date {
      font-size: 23px;
      margin-top: -10px;
      margin-bottom: 40px;
    }
    .detail-desc {
      margin-bottom: 35px;
      line-height: 30px;
    }
  }
}
/* -------------适应移动端---------------- */
@media screen and (max-width: 700px) {
  .detail-container {
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .detail {
    width: 100%;
    padding: 10px 20px;
    .detail-title {
      font-size: 20px;
      letter-spacing: 2px;
    }
    .detail-date {
      font-size: 17px;
      margin-top: -10px;
      margin-bottom: 20px;
    }
    .detail-desc {
      margin-bottom: 20px;
      line-height: 23px;
    }
    img {
      width: 100%;
    }
  }
}
</style>
